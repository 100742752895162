<template>
  <div>
    <v-text-field
      dense
      outlined
      counter
      @keypress.enter="focus('.code input')"
      :label="$t('data.name')"
      :rules="[ v => $helper.validator.requiredRule(v) ]"
      autofocus
      maxlength="100"
      v-model="formData.name"
    ></v-text-field>

    <v-select
      dense
      outlined
      counter
      :label="$t('accounting.data.type')"
      :items="types"
      v-model="formData.type"
    ></v-select>

    <v-text-field
      dense
      outlined
      counter
      :label="$t('data.amount')"
      maxlength="8"
      :rules="[ v => $helper.validator.requiredRule(v) ]"
      v-model.number="formData.amount"
    ></v-text-field>

    <v-text-field
      dense
      outlined
      readonly
      clearable
      :value="formData.order_id"
      :label="$t('accounting.data.order_id')"
      v-model.number="formData.order_id"
    >
      <template #append-outer>
        <v-btn
          color="primary"
          small
          @click="choiceOrder"
        >
          {{'order.action.choice_order'| t}}
        </v-btn>
      </template>
    </v-text-field>

    <div class="primary--text">
      {{help}}
    </div>
  </div>
</template>

<script lang="babel" type="text/babel">
import listCreatePopupMixin from '@/components/list/listCreateButton/listCreatePopupMixin'
export default {
  mixins: [listCreatePopupMixin],
  data: (vm) => ({
    formData: {
      name: '',
      type: vm.$accoutingConstants.TYPE_REVENUE,
      amount: 0,
      order_id: null,
    },
  }),
  methods: {
    async choiceOrder() {
      this.$orderSelector({
        applyCallback: data => {
          this.formData.order_id = data.id
        },
      })
    },
  },
  computed: {
    help() {
      return this.$t(`accounting.data.type.${this.formData.type}.help`)
    },
    types() {
      return this.$accoutingTypes.map(type => ({
        text: this.$t(`accounting.data.type.${type}`),
        value: type,
      }))
    },
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('updateData', window.eagleLodash.cloneDeep(this.formData))
      },
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
